<!--  -->
<template>
  <div class="rob">
    <img class="bg" src="../../images/rob/bg.png" alt="" srcset="" />
    <div class="head_NavBar">
      <van-icon name="arrow-left" size="20" color="#fff" @click="back()" />
      <div class="title">定时抢券</div>
    </div>
    <img class="meiri" src="../../images/rob/meiri.png" />
    <div class="title_box">
      <div class="t1">瓜分天府书展惠民券</div>
      <div class="t2">拼手速抢券</div>
      <div class="t3">活动时间：11.18 — 11.27</div>
    </div>
    <div class="juan_cont">
      <div class="juan_box">
        <img
          class="j_bg"
          src="http://tfbookfair2022.symansbon.cn/images/rob/t1.png"
        />
        <router-link to="/zhuchang">
          <img
            class="j_btn"
            src="http://tfbookfair2022.symansbon.cn/images/rob/b1.png"
          />
        </router-link>
      </div>
      <div class="juan_box">
        <img
          class="j_bg"
          src="http://tfbookfair2022.symansbon.cn/images/rob/t2.png"
        />
        <router-link to="/yunchang">
          <img
            class="j_btn"
            src="http://tfbookfair2022.symansbon.cn/images/rob/b2.png"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { NavBar, Icon } from "vant";
export default {
  name: "robDiscount",
  //import引入的组件需要注入到对象中才能使用
  components: {
    [Icon.name]: Icon,
  },
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    back() {
      this.$router.back();
    },
    run() {},
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.postRequest(
    //   "http://tfbookfairapi.symansbon.cn/WxisFollow/iswxfollow ",
    //   {}
    // ).then((res) => {
    //   //console.log(res);
    // });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="less">
.rob {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #ce3444;
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.head_NavBar .title {
  color: #fff;
}
.meiri {
  position: fixed;
  width: 90px;
  right: 0;
  top: 71px;
}
.title_box {
  position: relative;
  z-index: 1;
  text-align: center;
  line-height: 1.4;
  margin-top: 45px;
  .t1 {
    font-size: 37px;
    font-weight: 400;
    color: #ffffff;
  }
  .t2 {
    font-size: 28px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 13px;
  }
  .t3 {
    font-size: 14px;

    font-weight: 500;
    color: #ffffff;
  }
}
.juan_cont {
  position: relative;
  width: 100%;
  padding: 0 27px;
  z-index: 1;
  margin-top: 45px;
  .juan_box {
    position: relative;
    width: 100%;
    &:nth-child(1) {
      margin-bottom: 40px;
    }
  }
  .j_bg {
    width: 100%;
  }
  .j_btn {
    position: absolute;
    width: 57px;
    right: 13px;
    top: 31px;
  }
}
</style>